.card-cont {
  display: flex;
  height: 100%;
  min-width: 200px;
  box-sizing: border-box;
  text-align: center;
  flex-direction: column;
  transition: transform 0.3s;
}


.card {
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)

  /* display: hidden; */
}

.card .hidden {
  opacity: 0;
  /* pointer-events: none; */
}

.card:hover .hidden {
  opacity: 1;
  /* pointer-events: all; */
}

.info {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(42, 42, 42, 0.7);
  transition: opacity 0.3s ease-in-out;
  /* background-blend-mode: multiply; */
  color: whitesmoke;
  padding: 10px
}

.stack {
  display: flex;
  padding:10px;
  justify-content: center;
  overflow-x: scroll;
}

.stack #icon{
  width: 50px;
  height: 50px;
}


.buttons {
  display: flex;
  justify-content:center;
  align-items: flex-end;
  gap: 40px;
  /* padding-top: 40px; */
}

/* .button1 {
  /* position: absolute;
  top: 200px;
  left: 100px; 
  background-color: rgb(0, 168, 252);
  color: azure;
  padding: 5px;
  border-radius: 7px;
  box-shadow: 0 0 2px 1px rgba(42, 42, 42, 0.3);
} 
*/
  .button {
  /* position: absolute;
  top: 200px;
  right: 100px; */
  background-color: rgb(7, 89, 130);
  color: azure;
  padding: 5px;
  border-radius: 7px;
  box-shadow: 0 0 2px 1px rgba(42, 42, 42, 0.3);
}

.button:hover {
  background-color: rgb(0, 168, 252);
  transition: ease-in-out 0.2s;
}

.card-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
}
.img-div { 
  background-color: rgba(42, 42, 42, 0.3);
  border-radius: 30px;
  width: 400px;
  height: 250px;
  
}

.card-cont h3 {
  /* display: inline-flex; */
  /* position: relative; */
  /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
  /* padding: 5px; */
  /* margin: auto; */
  align-self: flex-start;
  margin-left: 10px;
  font-weight: 700;
  color: var(--white-text);
}
