.footer {
  width: 100%;
  /* height: 10vh; */
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
}

.icons {
  width: 400px;
  /* overflow: hidden; */
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: xx-large;
  padding-bottom: 7px;
  color: var(--white-text)
}

.footer-button {
  transition: transform 0.3s, color 0.3s;
}

.footer-button:hover {
  /* transform: scale(1.05); */
  display: block;
  color: var(--linkedin);
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
  text-decoration: none;
}

.copyright {
  /* position:absolute; */
  margin: 0px;
  color: var(--white-text);
  font-size: 10px;
}

@media screen and (max-width: 700px) {
  .icons {
    width: 100vw;
    padding-left: 20vmin;
    padding-right: 20vmin;
    justify-content: space-between;
    
  }
}