.container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* margin-left: 30px; */
  color: var(--white-text);
  /* overflow: hidden; */
}

.container h1 {
  font-size: 50px;
  margin: 0;
  padding-top: 20px;
  text-shadow: 1px 2px 1px #111a;
}

.container h2 {
  padding-top: 30px;
}

.container h3 {
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  padding-top: 15px;
}

.underline {
  height: 1px;
  background-color: #fffc;
  width: 90vmax;
  margin-top: 6px;
}

.profile {
  display: inherit;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 6vmin;
  padding-bottom: 10vmin;
}

.title {
  overflow: hidden;
}

.text {
  max-width: 50vmax;
}

.photo {
  /* max-height: auto; */
  width: 45vmin;
  background-color: #eee6;
  /* padding-left: 50px; */
  margin-top: 8vmin;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  align-self: flex-start;
}

.question {
  font-style: bold;
  font-weight: 600;
}

.answer {
  font-style: italic;
  font-weight: 700;
}

.margin {
  padding-left: 8vmin;
  padding-right: 8vmin;
}

.faq {
  content-visibility: hidden;
}

@media screen and (max-width: 700px) {
  .container,
  .profile {
    justify-content: center;
    align-self: center;
  }
  .profile {
    flex-direction: column-reverse;
  }

  .title,
  .underline {
    max-width: 100vw;
    overflow: hidden;
    text-align: center;
  }
  .title h1 {
    font-size: 10vmin;
  }
  .photo {
    width: 70vw;
    align-self: center;
  }

  .text {
    text-align: justify;
  }
}

@media screen and (max-width: 1200px) {
  .photo {
    max-width: 50vw;
    margin-top: 3vmin;
  }
  .text {
    align-self: flex-start;
    justify-content: space-evenly;
    margin-top: 2vmin;
  }
  .bottom {
    content-visibility: hidden;
  }
  .faq {
    content-visibility: visible;
    padding-bottom: 5vmin;
  }
  .profile {
    padding-bottom: 0;
  }
  .container h2 {
    padding: 0;
  }
  .paragraph h2 {
    margin-top: 0;
    padding-top: 3vmin;
  }
}
