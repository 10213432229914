

.imageContainer {
  height: 100vmin;
  /* width: 100%; */
  position: flex;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.3);
  background-blend-mode: multiply;
  transition: ease-in-out 3s 1s;
  -webkit-transform-style: preserve-3d;
  /* -webkit-transform: translate3d(0, 0, 0); */
  -webkit-backface-visibility: hidden;
}

.hero-title {
  /* position: relative; */
  overflow:hidden;
  display: inherit;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  letter-spacing: 2vw;
  align-self: center;
  margin: auto;
  /* vertical-align: center; */
  padding-top: 40vmin;
  font-size: 7vw;
  text-shadow: 0px 2px 10px #111a ;
}

.subtitle {
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  color: #fffc;
  letter-spacing: 1px;
  align-items: center;
  margin: auto;
  vertical-align: center;
  padding-top: 10px;
  font-size: 1.3em;
  text-shadow: 3px 4px 10px #111a ;
  
}

@media screen and (max-width: 700px) {
  .imageContainer{
    width: auto;
    height: 100vh;
  }

  .hero-title{
    font-size: 10vmin;
    padding-top: 25vmax;

  }

  .subtitle{
    font-size: 4vmin;
  }
}

@media screen and (max-width: 1200px) {

}