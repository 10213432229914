.mask {
  height: calc(100vh - 4vh);
  width: 100vw;
  z-index: 1000;
  background-color: var(--secondary);
  position: absolute;
  transition: opacity 0.3s ease-in-out, z-index 0.6s ease-in-out;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mask-on {
  opacity: 1;
}

.mask-off {
  opacity: 0;
  z-index: -1000;
}

.spinner {
  border-left: 3px solid var(--primary);
  border-top: 3px solid var(--primary);
  width: 60px;
  height: 60px;
  border-radius: 30px;
  animation-name: rotate;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.23, 0.86, 0.85, 0.53);
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes fade {
  0% {
    z-index: 1000;
  }
  50% {
    z-index: 1000;
  }
  100% {
    z-index: -1000;
  }
}
