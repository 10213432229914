
.project {
  background-color: var(--dark-grey);
  /* overflow: hidden; */
}


.project h1 {
  /* margin-left: 8vmin; */
  align-self:flex-start;
}

.display {
  display: inherit;
  justify-content: center;
  position: relative;
  flex-wrap: wrap;
  height: auto;
  /* min-width: 700px; */
  gap:30px;
  padding: 10vmin 0vmin;
}

@media screen and (max-width: 700px) {

  .project h1 {
    /* text-align: center; */
    font-size: 7vmin;
    align-self: center;
  }
}

